import Component from '../classes/Component';

export default class Hamburger extends Component {
	constructor() {
		super({
			element: 'nav',
			elements: {
				menu: '.menu',
				icon: '.menu__icon',
				line1: '.menu__icon__line--1',
				line1: '.menu__icon__line--2',
			},
		});

		this.isOpen = false;
	}

	create() {
		this.addEventListeners();
	}

	toggleState() {
		this.isOpen = !this.isOpen;

		if (this.isOpen) {
			this.element.classList.add('open');
		} else {
			this.element.classList.remove('open');
		}
	}

	addEventListeners() {
		this.elements.icon.addEventListener('click', this.toggleState.bind(this));
	}
}
